<template lang="pug">
section#v-rewards: .container
  v-title(desktop-align='center') 報名好康拿不完
  p
    | 搶先報名線上技術盛會，正式上線我們將通知您，準時收看即可獲得
    strong 《 4 大關鍵雲端主題技術白皮書》
    | ，活動前 100 位報名者加贈質感線材收納包，完整觀看議程影片並填寫問卷再抽 AirPods！另有下列精選好禮！
  .main
    ul
      li
        img(src='@/assets/reward-photo-1.png', alt='AirPods 1名', :width='$store.state.mobile ? 136 : 180')
        .name
          | AirPods
          br
          sub
            | （市價 NT$5,290）
          br
          | 1名
      li
        img(src='@/assets/reward-photo-2.png', alt='質感線材收納包 100名', :width='$store.state.mobile ? 136 : 180')
        .name
          | Microsoft 證照考試劵
          br
          sub
            | （市價 NT$3,500）
          br
          | 5名
      li
        img(src='@/assets/reward-photo-3.png', alt='質感線材收納包 100名', :width='$store.state.mobile ? 136 : 180')
        .name
          | 高效行動電源
          br
          | 12名
      li
        img(src='@/assets/reward-photo-4.png', alt='質感線材收納包 100名', :width='$store.state.mobile ? 136 : 180')
        .name
          | 質感線材收納包
          br
          | 100名
    a(href='https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__sL6fBVUMVlSS000OU1XNU02SzBWRTc3S1haVDNMRC4u', target='_blank') 按此填寫問卷
  .others
    h3 其他精選好禮
    ul
      li 超實用電源轉接器 * 3名
      li 迷你型 USB 隨身碟 * 6名
      li 微軟聯名 T-shirt (款式隨機) * 15名
      li 微軟聯名鴨舌帽 * 3名
      li 微軟聯名保溫杯 * 5名
  .rules
    h3 活動資訊
    label
      input(v-if='$store.state.mobile', type='checkbox', hidden)
      ol
        li 抽獎活動自即日起至2021年6月11日止。
        li 獎品內容與規格皆以實物為準，得獎人不得要求獎項更換或轉讓。
        li 主辦單位保有隨時修改及終止本活動之權利，如有任何變更內容或詳細注意事項，將於本網頁公布，恕不另行通知。
        li 主辦單位會以E-Mail通知得獎者，如三天內未收到回覆則視同放棄，主辦單位擁有最終決定權。
        li 如遇不可抗力之突發因素，主辦單位保有活動議程、主講者等變更之權利，報名完成者將以email形式收到註冊信，已結束場次將另行提供觀看連結。
        li
          | 詳細獎項兌獎方式請參閱：
          a(href='/主辦單位蒐集個資聲明及抽獎注意事項.pdf', target='_blank') 「主辦單位蒐集個資聲明及抽獎注意事項」
      .expander(v-if='$store.state.mobile') 顯示完整活動資訊
</template>

<script>
import VTitle from '@/components/Title.vue'

export default {
  name: 'Rewards',
  components: {
    VTitle,
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-rewards
  +padding-y(28px, 0)
p
  +font(16px, white, 1.5, .05em, null, justify)
  +margin-y(20px, 56px)
  strong
    color: #99F4F8
.main
  margin-bottom: 48px
  text-align: center
  ul
    display: grid
    grid-auto-flow: column
    grid-gap: 20px
    grid-template-columns: repeat(4, 232px)
    list-style-type: none
    margin-left: -20px
    margin-bottom: 40px
    overflow-x: auto
    padding-bottom: 8px
    padding-left: 20px
    width: calc(100% + 40px)
    &::after
      content: ''
      display: block
      width: 20px
    &::-webkit-scrollbar
      display: none
    > li
      text-align: center
      img
        margin-bottom: 12px
      .name
        +font(16px, white, 1.3, .05em, null, center)
        sub
          bottom: .25em
  a
    +font(18px, #99F4F8, null, .05em, bold, center)
    text-decoration: underline
.others
  margin-bottom: 40px
  h3
    +font(16px, #99F4F8, null, .1em, 500)
    margin-bottom: 16px
  ul
    list-style-type: none
    margin-bottom: 40px
    > li
      +font(16px, white, null, .05em)
      &:not(:last-child)
        margin-bottom: 8px
.rules
  h3
    +font(16px, white, null, .05em, bold)
    margin-bottom: 8px
  label
    display: block
    position: relative
    pointer-events: none
    input[type='checkbox']:checked
      & + ol
        max-height: 320px
        transition: max-height .4s
      & ~ .expander
        opacity: 0
        pointer-events: none
        transition: opacity .2s
    ol
      padding-bottom: 48px
      overflow-y: hidden
      max-height: 96px
      > li
        +font(12px, white, 1.8, .05em)
        a
          pointer-events: auto
          text-decoration: underline
    .expander
      +ifont(14px, white, 136px, .05em)
      +position(absolute, null, -20px, 0, -20px)
      background: linear-gradient(0deg, #0B0D28 0%, #0B0D28 40%, rgba(#0B0D28, 0) 100%)
      cursor: pointer
      display: block
      height: 108px
      pointer-events: auto
      text-align: center

@media (min-width: 1000px)
  #v-rewards
    +padding-y(0, 80px)
  p
    +font(20px, null, null, null, null, center)
    +margin-y(28px, 40px)
  .main
    ul
      grid-template-columns: repeat(4, 240px)
      justify-content: space-around
      margin-left: 0
      overflow-x: visible
      padding-left: 0
      width: auto
      &::after
        display: none
      > li
        img
          margin-bottom: 32px
        .name
          font-size: 20px
    a
      font-size: 20px
  .others
    margin-bottom: 52px
    h3
      font-size: 18px
    ul
      > li
        font-size: 18px
  .rules
    h3
      font-size: 18px
    label
      ol
        max-height: unset
        padding-bottom: 0
        > li
          font-size: 14px
</style>

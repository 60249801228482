export default {
  1: {
    photo: require('@/assets/speaker-photo-1.jpg'),
    name: 'Brendan Burns',
    title: 'Kubernetes 共同創辦人暨微軟雲端平台、開源、雲源生運算副總裁',
  },
  2: {
    photo: require('@/assets/speaker-photo-2.jpg'),
    name: 'Scott Guthrie',
    title: '微軟 雲端與人工智慧部門執行副總裁',
  },
  3: {
    photo: require('@/assets/speaker-photo-3.jpg'),
    name: 'Brandon Pulsipher',
    title: 'Adobe 雲端工程與營運副總裁',
  },
  4: {
    photo: require('@/assets/speaker-photo-4.jpg'),
    name: 'John Gossman',
    title: '微軟 傑出工程師',
  },
  5: {
    photo: require('@/assets/speaker-photo-5.jpg'),
    name: 'Stormy Peters',
    title: '微軟 開源方案總監',
  },
  6: {
    photo: require('@/assets/speaker-photo-6.jpg'),
    name: 'Oren Kashi',
    title: '紅帽 技術行銷經理',
  },
  7: {
    photo: require('@/assets/speaker-photo-7.jpg'),
    name: 'Ahmed Sabbour',
    title: '微軟 資深產品行銷經理',
  },
  8: {
    photo: require('@/assets/speaker-photo-8.jpg'),
    name: 'Sarah Novotny',
    title: '微軟 Azure Office CTO',
  },
  9: {
    photo: require('@/assets/speaker-photo-9.jpg'),
    name: 'Matt Hicks',
    title: '紅帽 產品與技術執行副總裁',
  },
  10: {
    photo: require('@/assets/speaker-photo-10.jpg'),
    name: 'Thomas Di Giacomo',
    title: 'SUSE 工程暨創新總裁',
  },
  11: {
    photo: require('@/assets/speaker-photo-11.jpg'),
    name: 'Armon Dadgar',
    title: 'HashiCorp 聯合創辦人暨 CTO',
  },
  12: {
    photo: require('@/assets/speaker-photo-12.jpg'),
    name: 'Steve Mayzak',
    title: 'Elastic CTO',
  },
  13: {
    photo: require('@/assets/speaker-photo-13.jpg'),
    name: 'Arun C. Murthy',
    title: 'Cloudera Chief Product Officer',
  },
  14: {
    photo: require('@/assets/speaker-photo-14.jpg'),
    name: '李御安',
    title: '台灣微軟 客戶成功事業群 雲端解決方案架構師',
  },
  15: {
    photo: require('@/assets/speaker-photo-15.jpg'),
    name: '黃耀逸',
    title: '台灣微軟 技術中心 (MTC)  雲端架構技術顧問',
  },
  16: {
    photo: require('@/assets/speaker-photo-16.jpg'),
    name: '張令儀',
    title: '微軟 亞太區人工智慧暨機器學習領域專家',
  },
  17: {
    photo: require('@/assets/speaker-photo-17.jpg'),
    name: '呂欣育',
    title: '台灣微軟 雲平台解決方案副總經理',
  },
  18: {
    photo: require('@/assets/speaker-photo-x.jpg'),
    name: '楊旺儒',
    title: '華碩 AI 研發中心（AICS）產品總監',
  },
  19: {
    photo: require('@/assets/speaker-photo-19.jpg'),
    name: '施立成',
    title: '微軟 全球助理法務長暨台灣微軟公共暨法律事務部總經理',
  },
  20: {
    photo: require('@/assets/speaker-photo-20.jpg'),
    name: '謝宗佑',
    title: '台灣微軟 Azure產品行銷經理',
  },
  21: {
    photo: require('@/assets/speaker-photo-21.jpg'),
    name: '林雅惠',
    title: '台灣微軟 客戶成功事業群 雲端解決方案架構師',
  },
  22: {
    photo: require('@/assets/speaker-photo-22.jpg'),
    name: '葉文和',
    title: '精誠軟體 資深處長',
  },
  23: {
    photo: require('@/assets/speaker-photo-23.jpg'),
    name: '俞伯翰',
    title: '自由系統 總經理',
  },
  24: {
    photo: require('@/assets/speaker-photo-24.jpg'),
    name: '黃建笙',
    title: '登豐數位科技 總經理',
  },
  25: {
    photo: require('@/assets/speaker-photo-25.jpg'),
    name: '蕭博仁',
    title: '台灣微軟 資深雲端產品經理',
  },
  26: {
    photo: require('@/assets/speaker-photo-x.jpg'),
    name: '王育民',
    title: '台灣微軟 專家技術部雲端架構技術顧問',
  },
  27: {
    photo: require('@/assets/speaker-photo-x.jpg'),
    name: 'Harry Chang',
    title: '台灣微軟 客戶技術策略師',
  },
  28: {
    photo: require('@/assets/speaker-photo-28.jpg'),
    name: '李啓後',
    title: '台灣微軟 物聯網卓越中心副總經理',
  },
  29: {
    photo: require('@/assets/speaker-photo-29.jpg'),
    name: '張恩言',
    title: '微軟 亞太區雲端事業群副總經理',
  },
  30: {
    photo: require('@/assets/speaker-photo-30.jpg'),
    name: '黃文傑',
    title: '伸波通訊 CEO',
  },
  31: {
    photo: require('@/assets/speaker-photo-31.jpg'),
    name: '毋文涵',
    title: '華碩 AI 研發中心 (AICS) Medical AI Product Director',
  },
  32: {
    photo: require('@/assets/speaker-photo-32.jpg'),
    name: '鄭斐文',
    title: '華碩 AI 研發中心 (AICS) Medical AI Engineering Director',
  },
  33: {
    photo: require('@/assets/speaker-photo-33.jpg'),
    name: '黃慧兒',
    title: '台灣微軟 專家技術部雲端架構技術顧問',
  },
  34: {
    photo: require('@/assets/speaker-photo-34.jpg'),
    name: '黃承皓',
    title: '台灣微軟 客戶成功事業群 雲端解決方案架構師',
  },
  35: {
    photo: require('@/assets/speaker-photo-35.jpg'),
    name: '鄒昀倢',
    title: 'TechOrange 主編 ',
  },
}

<template lang="pug">
#v-cloudnative
  v-main.cloudnative
    template(#title): h2 App 已死？雲端開發生態大爆發
    template(#datetime): time 2021/4/21 12:30PM
    template(#description): p 全球半數以上的後端開發人員都在使用「雲原生容器」技術，除了提升開發靈活性，工程師還能有哪些期待？本單元將剖析雲原生新戰場的攻略地圖，從 「4 大雲原生技術情境」解析，到新工具「GitOps」應用，還可以有哪些超前部署的預備。同場邀請 5G 雲端通訊專家共同對談 5G 專網智慧工廠應用實例。
    template(#rewards)
      figure: img(src='@/assets/track-4-whitepaper-1.jpg', alt='信賴 Red Hat OpenShift 及 Microsoft Azure 服務的九大理由', :width='$store.state.mobile ? 120 : 210')
  v-divider
  v-agenda.cloudnative(:speaker-ids='speakerIds')
    template(#session-1)
      h3 數位轉型的下一個主戰場: 雲原生服務
      p 雲端運算讓創新加速成為可能，企業如何跟上這股高速變動？「雲原生」成 2021開發者必備技能 hashtag ，但面對 DevOps、容器化、微服務、混合雲等多元技術，微軟能提供哪些無痛轉換雲原生的新服務？
    template(#session-2)
      h3 DevOps 的下一個里程碑：GitOps 讓開發人員開大絕
      p 雲端開發數量大爆發，不僅基礎設施不斷變化，還有 CI/CD 管線配置工作，讓團隊每日面臨維護數千個微服務的重擔。新工具 GitOps 如何解決這項挑戰？企業又能如何評估是否適合導入呢？
    template(#session-3)
      h3 4 大雲原生技術通用情境，讓數據引領創新
      p 2021 年雲原生技術將成為軟體開發的典範轉移，讓 IT 管理可以因應大規模、彈性與快速變更的需求。本場講堂將剖析 4 個雲原生開發的常用情境，透過案例解析 Modern Application、SaaS 應用、Telemetry 與 Geo Distrbuted App 的架構設計考量。
    template(#session-4)
      h3 雲原生與 5G 未來數據創新高峰
      p 雲原生將如何助攻 5G 應用？邀請專家以智慧工廠建造 5G 專網的台灣案例做解析，台灣企業想佈局轉型，可以從哪些技術策略切入，帶來哪些商業新火花？
  v-divider
  #form: v-form
  v-divider
  v-license
  v-divider
  v-footer
</template>

<script>
import VMain from '@/components/TrackMain.vue'
import VAgenda from '@/components/TrackAgenda.vue'
import VForm from '@/components/Form.vue'
import VLicense from '@/components/License.vue'
import VDivider from '@/components/Divider.vue'
import VFooter from '@/components/Footer.vue'

export default {
  name: 'Cloudnative',
  components: {
    VMain,
    VAgenda,
    VForm,
    VLicense,
    VDivider,
    VFooter,
  },
  data() {
    return {
      speakerIds: {
        session1: [25],
        session2: [33],
        session3: [34],
        session4: [35, 28, 30],
      },
    }
  },
  created() {
    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

</style>

<template lang="pug">
section#v-license: .container
  p
    | 雲端練等拿
    |
    strong Microsoft 認證
    br
    | 跨過技術門檻天下就是你的
  a(href='https://docs.microsoft.com/zh-tw/learn/certifications/', target='_blank')
    span 點我了解
    img(src='@/assets/external-icon.png', alt='外部連結', :width='$store.state.mobile ? 16 : 24')
</template>

<script>
export default {
  name: 'License',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-license
  +background((url(~@/assets/home-main-background-1.png), url(~@/assets/home-main-background-2.png)), auto 140px, no-repeat, bottom center)
  +padding-y(48px, 52px)
  text-align: center
p
  +font(20px, white, 1.5, .05em)
  font-family: 'DotGothic16'
  margin-bottom: 20px
  strong
    color: #BF7DF5
a
  +iflex(center, center)
  +ifont(16px, white, null, .05em)
  +size(120px, 40px)
  background-color: #3513DC
  &:hover
    text-decoration: none
  img
    margin-left: .5em

@media (min-width: 1000px)
  #v-license
    +padding-y(48px, 60px)
    background-size: 100% 140px
    p
      font-size: 30px
      margin-bottom: 32px
    a
      +size(200px, 60px)
      font-size: 24px
</style>

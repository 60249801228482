<template lang="pug">
section#v-tracks: .container
  v-title(:highlight='true') 4 大關鍵雲端主題
  ul.sessions
    li
      strong 4 場
      p 雲端趨勢演講
    li
      strong 8 場
      p 技術講堂
    li
      strong 4 場
      p 產業案例對談
  ul.tracks
    li: router-link(to='/opensource?utm_source=hp_unit01&utm_medium=cpc')
      h3
        | Open Source
        figure: img(src='@/assets/track-icon-1.png', alt='Open Source', width='48')
      h4 微軟開源新佈局，創新連擊全面加速
      p 微軟 ❤️ Open Source！Kubernetes 聯合創辦人親自解密，如何面對混合雲、容器化趨勢？
      // .date
      //   label 首播日期：
      //   time 3/31
      .more
        | 了解更多
        span >>
    li: router-link(to='/data_ai?utm_source=hp_unit02&utm_medium=cpc')
      h3
        | Data & AI
        figure: img(src='@/assets/track-icon-2.png', alt='Data & AI', width='48')
      h4 新一波 AI 競賽起跑，數據致勝
      p 開發敏捷度如何不被「雲海數據」拖垮？視覺辨識、機器學習進階版，滿滿技術實戰精華不藏私！
      // .date
      //   label 首播日期：
      //   time 4/7
      .more
        | 了解更多
        span >>
    li: router-link(to='/cloudsecurity?utm_source=hp_unit03&utm_medium=cpc')
      h3
        | Cloud Security
        figure: img(src='@/assets/track-icon-3.png', alt='Cloud Security', width='48')
      h4 來一劑 2021 雲端資安疫苗，防禦駭客出新招
      p 雲端資安如何與傳統資安不一樣？把資安當作最高等級企業投資，用機器學習掌握駭客攻擊途徑，打造防堵駭客防護網。
      // .date
      //   label 首播日期：
      //   time 4/14
      .more
        | 了解更多
        span >>
    li: router-link(to='/cloudnative?utm_source=hp_unit04&utm_medium=cpc')
      h3
        | Cloud Native Application
        figure: img(src='@/assets/track-icon-4.png', alt='Cloud Native Application', width='48')
      h4 App 已死？雲端開發生態大爆發
      p 雲原生、K8s 開發變成 IT 新常態，如何把微服務架構發揮得淋漓盡致？5G 高速數據傳輸，企業備戰預備！
      // .date
      //   label 首播日期：
      //   time 4/21
      .more
        | 了解更多
        span >>
</template>

<script>
import VTitle from '@/components/Title.vue'

export default {
  name: 'Tracks',
  components: {
    VTitle,
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-tracks
  +padding-y(28px, 40px)
.sessions
  +margin-y(28px, 28px)
  display: grid
  grid-template-columns: repeat(3, 1fr)
  justify-content: center
  list-style-type: none
  > li
    text-align: center
    strong
      +font(36px, white)
      display: block
      font-family: 'DotGothic16'
      margin-bottom: 12px
      text-shadow: 0 0 8px rgba(white, .6)
    p
      +font(14px, white, 1.5, .05em)
      font-family: 'DotGothic16'
.tracks
  list-style-type: none
  display: grid
  grid-gap: 24px
  grid-template-rows: repeat(4, auto)
  > li
    position: relative
    a
      display: block
      height: 100%
      padding: 72px 20px 20px
      &:hover
        text-decoration: none
    h3
      +font(18px, black, 50px, null, bold)
      +position(absolute, 0, 0, null, 0)
      font-family: 'Source Code Pro'
      height: 50px
      padding-left: 20px
      figure
        +flex(center, center)
        +position(absolute, 0, 0)
        +size(48px)
        background-color: black
    h4
      +font(18px, white, null, .05em, bold)
      margin-bottom: 12px
    p
      +font(16px, white, 1.5, .05em)
      margin-bottom: 36px
    .date
      +font(16px, null, null, .05em, bold)
    .more
      +font(18px, null, null, .05em, bold)
      +position(absolute, null, 20px, 20px)
      span
        font-family: 'DotGothic16'
        font-size: 28px
        margin-left: .5em
    &:nth-child(1)
      +border(2px, #99F4F8)
      h3
        background-color: #99F4F8
      .date
        color: #99F4F8
      .more
        color: #99F4F8
    &:nth-child(2)
      +border(2px, #BF7DF5)
      h3
        background-color: #BF7DF5
      .date
        color: #BF7DF5
      .more
        color: #BF7DF5
    &:nth-child(3)
      +border(2px, #FCF1E6)
      h3
        background-color: #FCF1E6
      .date
        color: #FCF1E6
      .more
        color: #FCF1E6
    &:nth-child(4)
      +border(2px, #EA335E)
      h3
        background-color: #EA335E
      .date
        color: #EA335E
      .more
        color: #EA335E

@media (min-width: 1000px)
  #v-tracks
    +background(url(~@/assets/home-main-background-1.png), 100% 140px, no-repeat, bottom center)
    +padding-y(0, 200px)
  .sessions
    +margin-y(60px, 80px)
    > li
      strong
        font-size: 64px
        margin-bottom: 24px
      p
        font-size: 20px
  .tracks
    display: grid
    grid-gap: 20px
    grid-template: repeat(2, 1fr) / repeat(2, 1fr)
</style>

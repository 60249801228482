<template lang="pug">
section#v-agenda: .container
  v-title(desktop-align='left') 議程
  .session
    label 雲端趨勢演講
    slot(name='session-1')
    ul.speakers
      li(v-for='speaker of mapToSpeakers(speakerIds.session1)')
        img(:src='speaker.photo', :alt='`${speaker.title} ${speaker.name}`', width='120')
        .name {{ speaker.name }}
        .title {{ speaker.title }}
  .session
    label 技術講堂(1)
    slot(name='session-2')
    ul.speakers
      li(v-for='speaker of mapToSpeakers(speakerIds.session2)')
        img(:src='speaker.photo', :alt='`${speaker.title} ${speaker.name}`', width='120')
        .name {{ speaker.name }}
        .title {{ speaker.title }}
  .session
    label 技術講堂(2)
    slot(name='session-3')
    ul.speakers
      li(v-for='speaker of mapToSpeakers(speakerIds.session3)')
        img(:src='speaker.photo', :alt='`${speaker.title} ${speaker.name}`', width='120')
        .name {{ speaker.name }}
        .title {{ speaker.title }}
  .session
    label 產業案例對談
    slot(name='session-4')
    ul.speakers
      li(v-for='speaker of mapToSpeakers(speakerIds.session4)')
        img(:src='speaker.photo', :alt='`${speaker.title} ${speaker.name}`', width='120')
        .name {{ speaker.name }}
        .title {{ speaker.title }}
</template>

<script>
import { props } from 'ramda'
import speakers from '@/data/speakers.js'
import VTitle from '@/components/Title.vue'

export default {
  name: 'Agenda',
  components: {
    VTitle,
  },
  props: {
    speakerIds: {
      type: Object,
      required: true,
    },
  },
  methods: {
    mapToSpeakers(speakerIds) {
      return props(speakerIds, speakers)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-agenda
  +padding-y(28px, 40px)
  &.opensource
    label
      color: #99F4F8
    .speakers
      > li
        img
          box-shadow: 6px 6px 0 #99F4F8
  &.dataai
    label
      color: #BF7DF5
    .speakers
      > li
        img
          box-shadow: 6px 6px 0 #BF7DF5
  &.cloudsecurity
    label
      color: #FCF1E6
    .speakers
      > li
        img
          box-shadow: 6px 6px 0 #FCF1E6
  &.cloudnative
    label
      color: #EA335E
    .speakers
      > li
        img
          box-shadow: 6px 6px 0 #EA335E
.session
  &:not(:last-of-type)
    border-bottom: 1px solid white
    padding-bottom: 40px
  label
    +font(16px, null, null, .05em, 500)
    +margin-y(28px, 8px)
    display: block
  h3
    +font(20px, white, 1.5, .05em, bold, justify)
    margin-bottom: 12px
  p
    +font(16px, white, 1.5, .05em, null, justify)
    margin-bottom: 54px
  .speakers
    display: grid
    grid-gap: 24px
    grid-template-columns: repeat(auto-fill, 120px)
    justify-content: space-around
    list-style-type: none
    > li
      img
        margin-bottom: 12px
      .name
        +font(16px, white, 1.2, null, bold)
        margin-bottom: 4px
      .title
        +font(12px, white, 1.5)

@media (min-width: 1000px)
  #v-agenda
    +padding-y(0, 100px)
  .session
    padding-left: 200px
    label
      +margin-y(36px, 12px)
      font-size: 20px
    h3
      font-size: 30px
      margin-bottom: 16px
    p
      font-size: 20px
</style>

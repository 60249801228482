<template lang="pug">
h2.v-title(:class='mode'): slot
</template>

<script>
import { equals, includes } from 'ramda'

export default {
  name: 'Title',
  props: {
    desktopAlign: {
      validator(value) {
        return includes(value, ['left', 'center'])
      },
    },
    highlight: {
      type: Boolean,
    },
  },
  computed: {
    mode() {
      return {
        'desktop-align-left': equals(this.desktopAlign, 'left'),
        'desktop-align-center': equals(this.desktopAlign, 'center'),
        highlight: this.highlight,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

.v-title
  +font(20px, white, null, .05em)
  font-family: 'DotGothic16'
  &::before
    content: '# '
  &.highlight
    +font(24px, null, null, null, null, center)
    text-shadow: 0 0 8px rgba(white, .6)

@media (min-width: 1000px)
  .v-title
    font-size: 30px
    position: relative
    &::after
      +size(324px, 16px)
      background-color: white
      content: ''
      display: block
    &.desktop-align-left
      padding-top: 36px
      &::after
        +position(absolute, 0, null, null, 0)
    &.desktop-align-center
      padding-top: 36px
      text-align: center
      &::after
        +position(absolute, 0, null, null, 50%)
        transform: translateX(-50%)
    &.highlight
      font-size: 48px
      padding-top: 48px
      &::after
        +position(absolute, 0, null, null, 50%)
        transform: translateX(-50%)
</style>

<template lang="pug">
main#v-main: .container
  .logo
    img(src='@/assets/microsoft-logo.png', alt='Microsoft', :height='$store.state.mobile ? 20 : 24')
    img(src='@/assets/techorange-logo.png', alt='TechOrange 科技報橘', :height='$store.state.mobile ? 20 : 24')
  .keyview(v-if='!$store.state.mobile')
    img(src='@/assets/home-keyview.png', alt='CLOUD', :width='$store.state.mobile ? 320 : 540')
  h1
    | Cloud
    |
    br(v-if='$store.state.mobile')
    | Innovation
    |
    br(v-if='$store.state.mobile')
    | Summit
  h2 賦能雲端 驅動創新
  // .datetime
  //   label 線上技術盛會 活動期間
  //   time 即日起 - 2021/4/30
  a.shortcut(v-if='!$store.state.mobile', v-scroll-to='"#form"', href='#form') 立即觀看
</template>

<script>
export default {
  name: 'Main',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-main
  +background((url(~@/assets/home-main-background-1.png), url(~@/assets/home-main-background-2.png)), 100% 36px, no-repeat, bottom center)
  +padding-y(20px, 160px)
.logo
  margin-bottom: 40px
  img
    &:not(:last-child)
      margin-right: 20px
.keyview
  margin-bottom: 40px
  img
    +margin-x(auto)
    display: block
h1
  +font(48px, white, 1.5, .05em)
  font-family: 'DotGothic16'
  margin-bottom: 20px
  text-shadow: 0 0 8px rgba(white, .6)
h2
  +font(32px, white, null, .05em)
  margin-bottom: 40px
  text-shadow: 0 0 8px rgba(white, .6)
.datetime
  label
    +font(14px, white, null, .05em)
    display: block
    margin-bottom: 8px
  time
    +font(20px, white, null, .05em)
    font-family: 'DotGothic16'

@media (min-width: 1000px)
  #v-main
    +padding-y(40px, 280px)
    background-size: 100% 140px
  .container
    position: relative
  .logo
    margin-bottom: 96px
    img
      &:not(:last-child)
        margin-right: 48px
  .keyview
    +position(absolute, 240px, 0)
    margin-bottom: 0
  h1
    margin-bottom: 4px
  h2
    font-size: 36px
    margin-bottom: 72px
  .datetime
    margin-bottom: 52px
    label
      font-size: 16px
      margin-bottom: 8px
    time
      font-size: 32px
  .shortcut
    +background(url(~@/assets/primary-button-background.png), 100%, no-repeat, center center)
    +flex(center, center)
    +ifont(24px, white, null, .05em)
    +size(200px, 64px)
    &:hover
      text-decoration: none
</style>

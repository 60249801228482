<template lang="pug">
nav#v-menu(:class='{slided}')
  button.slide(@click='slided = true', type='button'): img(src='@/assets/menu-icon.png', alt='主選單', width='30')
  .dimmer(@click='slided = false')
  .menu
    button.close(@click='slided = false', type='button'): img(src='@/assets/white-close-icon.png', alt='關閉', width='28')
    router-link(@click.native='slided = false' to='/?utm_source=menu_all&utm_medium=cpc'): h1
      | Cloud
      |
      br(v-if='$store.state.mobile')
      | Innovation
      |
      br(v-if='$store.state.mobile')
      | Summit
    ul
      li: router-link(@click.native='slided = false', to='/opensource?utm_source=unit01_all&utm_medium=cpc') Open Source
      li: router-link(@click.native='slided = false', to='/data_ai?utm_source=unit02_all&utm_medium=cpc') Data & AI
      li: router-link(@click.native='slided = false', to='/cloudsecurity?utm_source=menu_unit03&utm_medium=cpc') Cloud Security
      li: router-link(@click.native='slided = false', to='/cloudnative?utm_source=menu_unit04&utm_medium=cpc') Cloud Native Application
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      slided: false,
    }
  },
  methods: {
    pushTo(path) {
      this.slided = false
      if(path !== this.$route.path) {
        this.$router.push(path)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-menu
  +position(fixed, 0, 0, 0, 0)
  pointer-events: none
  z-index: 99
  &.slided
    .dimmer
      opacity: 1
      pointer-events: auto
    .menu
      transform: translateX(0)
.slide
  +border(2px, white)
  +position(absolute, 20px, 20px)
  +size(48px)
  pointer-events: auto
  background-color: #0B0D28
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .8)
  opacity: 0
  transition: opacity .3s
.menu
  +position(absolute, 0, 0, 0)
  background-color: #0B0D28
  box-shadow: 0 0 8px rgba(white, .6)
  padding-left: 40px
  padding-top: 60px
  pointer-events: auto
  transform: translateX(calc(100% + 8px))
  transition: transform .4s
  width: 240px
  .close
    +position(absolute, 12px, 12px)
  a
    &:hover
      text-decoration: none
    h1
      +font(24px, white, 1.5, .05em)
      font-family: 'DotGothic16'
      text-shadow: 0 0 8px rgba(white, .6)
      margin-bottom: 40px
  ul
    list-style-type: none
    +font(16px, white, 1.5, null, bold)
    text-transform: uppercase
    > li
      &:not(:last-child)
        margin-bottom: 28px
      a
        transition: color .2s
        &.router-link-exact-active
          color: #99f4f8

@media (min-width: 1000px)
  .slide
    +position(null, 40px, 40px)
  .menu
    padding-top: 120px
    padding-left: 60px
    width: 420px
    .close
      +position(null, 20px, 20px)
    h1
      margin-bottom: 80px
</style>

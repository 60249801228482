<template lang="pug">
#v-app
  transition(name='route', mode='out-in'): router-view
  // v-shortcut
  v-menu
  transition(name='dimmer'): v-popup(v-if='$store.state.popup')
</template>

<script>
import VShortcut from '@/components/Shortcut.vue'
import VMenu from '@/components/Menu.vue'
import VPopup from '@/components/Popup.vue'

export default {
  name: 'App',
  components: {
    VShortcut,
    VMenu,
    VPopup,
  },
  created() {
    document.cookie=`XSRF-TOKEN=${Math.random().toString(36).substr(2)}`
    $(window).resize(() => {
      const mobile = $(window).width() < 1000
      if(this.$store.state.mobile !== mobile) {
        this.$store.commit('setMobile', mobile)
      }
    }).resize()
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.route-enter-active, .route-leave-active
  transition: opacity .3s, transform .3s
.route-enter, .route-leave-to
  opacity: 0
  transform: translateY(-20px)
.dimmer-enter-active, .dimmer-leave-active
  transition: opacity .2s
.dimmer-enter, .dimmer-leave-to
  opacity: 0

#v-app
  +margin-x(auto)
  min-width: 360px
  padding-bottom: 52px

@media (min-width: 1000px)
  #v-app
    padding-bottom: 0
</style>

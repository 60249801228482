<template lang="pug">
section#v-introduction: .container
  v-title(desktop-align='left') 開發者召集令，集合！
  .content
    p
      | 2021 下個新十年， 4 大雲端技術即將讓未來世界不一樣
      br
      strong.track-1 開源
      | 、
      strong.track-2 AI 與大數據
      | 、
      strong.track-3 雲原生開發
      | 、
      strong.track-4 資安
      | 引爆技術新高點
    p
      | 集結微軟 Kubernetes 聯合創辦人Brendan Burns、全球開源領域重量級廠商、台灣第一線雲端專家群線上開講
      br
      | 萃取 2021 最新雲端開發技術精華
    p 線上一次囊括最頂尖業界代表，一起掌握滿滿的技術實作，挑戰雲端開發無極限！
    p 搶先報名線上技術盛會！正式上線我們將通知您，準時收看即可獲得《 4 大關鍵雲端主題技術白皮書》
</template>

<script>
import VTitle from '@/components/Title.vue'

export default {
  name: 'Introduction',
  components: {
    VTitle,
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-introduction
  +padding-y(28px, 40px)
.content
  margin-top: 28px
  p
    +font(16px, white, 1.5, .05em, 500, justify)
    &:not(:last-child)
      margin-bottom: 20px
    strong
      &.track-1
        color: #99F4F8
      &.track-2
        color: #BF7DF5
      &.track-3
        color: #FCF1E6
      &.track-4
        color: #EA335E

@media (min-width: 1000px)
  #v-introduction
    +padding-y(0, 80px)
  .container
    display: grid
    grid-gap: 100px
    grid-template-columns: auto 1fr
  .content
    margin-top: 36px
    p
      font-size: 18px
</style>

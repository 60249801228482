import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobile: null,
    utm: {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    },
    menu: false,
    popup: false,
  },
  mutations: {
    saveUtm(state, query) {
      state.utm.utm_source = query.utm_source ? query.utm_source : ''
      state.utm.utm_medium = query.utm_medium ? query.utm_medium : ''
      state.utm.utm_campaign = query.utm_campaign ? query.utm_campaign : ''
      state.utm.utm_term = query.utm_term ? query.utm_term : ''
      state.utm.utm_content = query.utm_content ? query.utm_content : ''
    },
    setMobile(state, mobile) {
      state.mobile = mobile
    },
    setMenu(state, menu) {
      state.menu = menu
    },
    setPopup(state, popup) {
      state.popup = popup
    },
  },
  actions: {
    submit(context, data) {
      return Vue.http.post('/api/registrant', data)
    },
  },
})

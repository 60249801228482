import Vue from 'vue'
import Router from 'vue-router'

import VHome from '@/views/Home.vue'
import VOpensource from '@/views/Opensource.vue'
import VDataAi from '@/views/DataAi.vue'
import VCloudsecurity from '@/views/Cloudsecurity.vue'
import VCloudnative from '@/views/Cloudnative.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, saved) {
    return new Promise(resolve => {
      const position = saved ? saved : { x: 0, y: 0 }
      setTimeout(() => resolve(position), 300)
    })
  },
  routes: [
    {
      path: '/',
      component: VHome,
    },
    {
      path: '/opensource',
      component: VOpensource,
    },
    {
      path: '/data_ai',
      component: VDataAi,
    },
    {
      path: '/cloudsecurity',
      component: VCloudsecurity,
    },
    {
      path: '/cloudnative',
      component: VCloudnative,
    },
    {
      path: '*',
      redirect: '/',
    },
  ]
})

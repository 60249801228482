<template lang="pug">
#v-dataai
  v-main.dataai
    template(#title): h2 新一波 AI 競賽起跑，數據致勝
    template(#datetime): time 2021/4/7 12:30PM
    template(#description): p 企業上雲僅是數位轉型的起步，如何「快速從海量數據獲取洞察」才是決勝關鍵！本主題將解密在雲端大數據、文字、影像等多元資料中，如何擅用「AI 與資料分析神器」，以及「電腦視覺判讀」提高開發敏捷度。同時邀請華碩 AICS 專家分享醫療 AIoT 應用實例。
    template(#rewards)
      figure: img(src='@/assets/track-2-whitepaper-1.jpg', alt='信賴 Red Hat OpenShift 及 Microsoft Azure 服務的九大理由', :width='$store.state.mobile ? 120 : 210')
      figure: img(src='@/assets/track-2-whitepaper-2.jpg', alt='信賴 Red Hat OpenShift 及 Microsoft Azure 服務的九大理由', :width='$store.state.mobile ? 120 : 210')
  v-divider
  v-agenda.dataai(:speaker-ids='speakerIds')
    template(#session-1)
      h3 2021 微軟 Ignite 重磅：後疫情時代 AI 與大數據新發布
      p 後疫時代如何使用對的工具搶先從資料中提前預測變化，挖掘商機，資料分析與 AI 將扮演重要關鍵！本場次將邀請專家重點解析 2021 Ignite 微軟全球開發者大會資料分析與 AI 重要發布，掌握最新最強開發動能，加速企業資料平台解決方案的落地運用。
    template(#session-2)
      h3 數位化業務「最強分析大腦」： Azure Synapse Analytics 實作
      p 企業想做好數位轉型，首重解決「數據孤島」困境，該如何迅速從各儲存系統取得深入解析的能力？Azure Synapse 帶你透過單一平台將 SQL、 Spark 技術、 ETL/ELT 管線，以及其他 Power BI 服務整合，加速分析流程。
    template(#session-3)
      h3 AI 落地實戰：那些關於電腦視覺你想像不到的事
      p 不久之前，AI人工智慧還像是科幻小說的情節；今天，我們已經可以將AI解決方案落地在各行各業的應用中，並使得日常工作流程有令人難以置信的改變，如果你對AI感到興趣、並想了解AI協助企業轉型的真實案例，你絕對不能錯過這個演講。我們將在議程中說明電腦視覺與機器學習等創新服務如何改變企業流程，解鎖AI新價值。
    template(#session-4)
      h3 AIoT 產業轉型不迷航，剖析關鍵數據策略
      p 領航智慧轉型、數據策略先行！企業常見的轉型困境：資料分散各系統，導致難以有效整合，甚至導入 AI 進行分析預測。本次邀請華碩 AI 研發中心（AICS），以其協助醫院活化數據，啟動精準醫療實例，分享雲端資料分析與 AI 應用落地的經驗。同時也請到微軟技術專家，從醫療產業的案例，延伸剖析台灣各產業數位轉型的最佳實踐與經營策略！
  v-divider
  #form: v-form
  v-divider
  v-license
  v-divider
  v-footer
</template>

<script>
import VMain from '@/components/TrackMain.vue'
import VAgenda from '@/components/TrackAgenda.vue'
import VForm from '@/components/Form.vue'
import VLicense from '@/components/License.vue'
import VDivider from '@/components/Divider.vue'
import VFooter from '@/components/Footer.vue'

export default {
  name: 'DataAi',
  components: {
    VMain,
    VAgenda,
    VForm,
    VLicense,
    VDivider,
    VFooter,
  },
  data() {
    return {
      speakerIds: {
        session1: [14],
        session2: [15],
        session3: [16],
        session4: [35, 17, 31, 32],
      },
    }
  },
  created() {
    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

</style>

<template lang="pug">
main#v-main: .container
  .logo
    img(src='@/assets/microsoft-logo.png', alt='Microsoft', :height='$store.state.mobile ? 20 : 24')
    img(src='@/assets/techorange-logo.png', alt='TechOrange 科技報橘', :height='$store.state.mobile ? 20 : 24')
  router-link.back(@click.native='slided = false' to='/?utm_source=unit_all&utm_medium=cpc'): h1
    | Cloud
    |
    br(v-if='$store.state.mobile')
    | Innovation
    |
    br(v-if='$store.state.mobile')
    | Summit
  slot(name='title')
  .datetime
    // label 首播時間
    // slot(name='datetime')
  slot(name='description')
  .rewards: slot(name='rewards')
  a.shortcut(v-if='!$store.state.mobile', v-scroll-to='"#form"', href='#form') 立即觀看
</template>

<script>
export default {
  name: 'Main',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-main
  +background((url(~@/assets/home-main-background-1.png), url(~@/assets/home-main-background-2.png)), 100% 36px, no-repeat, bottom center)
  +padding-y(20px, 76px)
  &.opensource
    .datetime
      color: #99F4F8
    .shortcut
      background-image: url(~@/assets/track-1-button-background.png)
  &.dataai
    .datetime
      color: #BF7DF5
    .shortcut
      background-image: url(~@/assets/track-2-button-background.png)
  &.cloudsecurity
    .datetime
      color: #FCF1E6
    .shortcut
      background-image: url(~@/assets/track-3-button-background.png)
  &.cloudnative
    .datetime
      color: #EA335E
    .shortcut
      background-image: url(~@/assets/track-4-button-background.png)
.logo
  margin-bottom: 32px
  img
    &:not(:last-child)
      margin-right: 20px
.back
  &:hover
    text-decoration: none
  h1
    +font(24px, white, 1.5, .05em)
    font-family: 'DotGothic16'
    text-shadow: 0 0 8px rgba(white, .6)
h2
  +font(30px, white, 1.5, .05em, bold)
  +margin-y(40px, 12px)
.datetime
  +font(20px, null, null, .05em, 500)
  margin-bottom: 28px
  label
    margin-right: .5em
p
  +font(16px, white, 1.5, .05em, null, justify)
  margin-bottom: 36px
.rewards
  margin-bottom: 36px
  figure
    box-shadow: 0 0 8px rgba(white, .6)
    position: relative
    &::after
      +ifont(12px, white, .05em)
      +position(absolute, null, 8px, 8px)
      +circle(32px)
      +flex(center, center)
      background-color: #EA335E
      content: '獲得'
    &:not(:last-child)
      margin-right: 16px
.shortcut
  +background(null, 100%, no-repeat, center center)
  +flex(center, center)
  +ifont(16px, white, null, .05em)
  +size(150px, 48px)
  &:hover
    text-decoration: none

@media (min-width: 1000px)
  #v-main
    +padding-y(40px, 80px)
    background-size: 100% 140px
    &.dataai
      .rewards
        figure
          &:nth-child(1)
            transform: scale(.8) translate(240px, -64px)
          &:nth-child(2)
            transform: scale(.8) translate(40px, 40px)
  .container
    position: relative
  .logo
    +position(absolute, 8px, null, null, 580px)
    img
      &:not(:last-child)
        margin-right: 48px
  .back
    h1
      +font(30px, null, 1.15)
  h2
    +margin-y(92px, 4px)
    font-size: 36px
  .datetime
    font-size: 28px
  p
    font-size: 20px
    margin-bottom: 52px
    max-width: 720px
  .rewards
    +position(absolute, 128px, 0)
    figure
      &::after
        +position(null, null, 12px, 12px)
        +size(60px)
        font-size: 16px
  .shortcut
    +size(200px, 64px)
    font-size: 24px
</style>

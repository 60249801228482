<template lang="pug">
#v-home
  v-main
  v-divider
  v-introduction
  v-divider
  v-tracks
  v-divider
  v-speakers(:speaker-ids='speakerIds')
  v-divider
  v-rewards
  v-divider
  #form: v-form
  v-divider
  v-license
  v-divider
  v-footer
</template>

<script>
import VMain from '@/components/HomeMain.vue'
import VIntroduction from '@/components/HomeIntroduction.vue'
import VTracks from '@/components/HomeTracks.vue'
import VSpeakers from '@/components/HomeSpeakers.vue'
import VRewards from '@/components/Rewards.vue'
import VForm from '@/components/Form.vue'
import VLicense from '@/components/License.vue'
import VFooter from '@/components/Footer.vue'
import VDivider from '@/components/Divider.vue'

export default {
  name: 'Home',
  components: {
    VMain,
    VIntroduction,
    VTracks,
    VSpeakers,
    VRewards,
    VForm,
    VLicense,
    VDivider,
    VFooter,
  },
  data() {
    return {
      speakerIds: [1, 2, 8, 5, 4, 7, 19, 16, 17, 28, 25, 20, 34, 21, 33, 15, 14, 3, 6, 9, 10, 11, 12, 13, 31, 32, 22, 23, 24, 30],
    }
  },
  created() {
    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

</style>

<template lang="pug">
section#v-speakers: .container
  v-title(desktop-align='left') 講者陣容
  ul
    li(v-for='speaker of mapToSpeakers(speakerIds)')
      img(:src='speaker.photo', :alt='`${speaker.title} ${speaker.name}`', :width='$store.state.mobile ? 120 : 180')
      .name {{ speaker.name }}
      .title {{ speaker.title }}
</template>

<script>
import { props } from 'ramda'
import speakers from '@/data/speakers.js'
import VTitle from '@/components/Title.vue'

export default {
  name: 'Speakers',
  components: {
    VTitle,
  },
  props: {
    speakerIds: {
      type: Array,
      required: true,
    },
  },
  methods: {
    mapToSpeakers(speakerIds) {
      return props(speakerIds, speakers)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-speakers
  +padding-y(28px, 40px)
ul
  display: grid
  grid-gap: 32px 52px
  grid-template-columns: repeat(auto-fill, 120px)
  justify-content: space-around
  list-style-type: none
  margin-top: 28px
  > li
    img
      box-shadow: 6px 6px 0 #BF7DF5
      margin-bottom: 12px
    .name
      +font(16px, white, 1.2, null, bold)
      margin-bottom: 4px
    .title
      +font(12px, white, 1.5)

@media (min-width: 1000px)
  #v-speakers
    +padding-y(0, 100px)
  ul
    grid-gap: 50px
    grid-template-columns: repeat(auto-fill, 180px)
    margin-top: 60px
    > li
      img
        margin-bottom: 20px
      .name
        font-size: 18px
      .title
        font-size: 14px
</style>

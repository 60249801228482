<template lang="pug">
#v-cloudsecurity
  v-main.cloudsecurity
    template(#title): h2 來一劑 2021 雲端資安疫苗，防禦駭客出新招
    template(#datetime): time 2021/4/14 12:30PM
    template(#description): p 2020 年 DDoS 攻擊較往年增加 50％以上！想加快雲端創新腳步，卻避不開資安漏洞？本主題將介紹 2 大資安新利器，如何在混合雲趨勢下掌握駭客攻擊途徑，在各端點智慧建立威脅警示與分析。同場邀請軟體、資訊架構顧問分享台灣最新資安洞察。
    template(#rewards)
      figure: img(src='@/assets/track-3-whitepaper-1.jpg', alt='信賴 Red Hat OpenShift 及 Microsoft Azure 服務的九大理由', :width='$store.state.mobile ? 120 : 210')
  v-divider
  v-agenda.cloudsecurity(:speaker-ids='speakerIds')
    template(#session-1)
      h3 Reimagine Security  雲端時代，企業必備的營運韌性與資安新思維
      p 企業上雲除了做好技術進擊，也不能忽略資安的策略布局！面對網路病毒、勒索軟體等惡意攻擊，微軟看見哪些產業新契機？又如何透過 AI 將資安化被動為主動，增添企業轉型新動能？
    template(#session-2)
      h3 混合雲「MVP 資安守門員」- Azure Defender 戰力分析
      p 面對資安管理複雜度提升，企業需要一個更全知、24 小時不間斷的資安監控工具，一次掌握駭客動向、提升防護可視度與處理合規問題！專家將 demo Azure Defender 如何依企業需求排定警示優先順序，智慧對抗 RDP 暴力密碼破解、 SQL 插入式攻擊等威脅。針對資安成本控管，專家也會提出務實建議。
    template(#session-3)
      h3 駭客退散！啟動 Azure Bastion 「黃金資安新策略」
      p 集中化的網路連線管理平台會是資安控管的新常態。如何佈建 VM 之間的網路管理、整合現有防火牆並建立有效安全界線？Azure Bastion 以 PaaS 服務管理作業系統的安全性， 建立安全且順暢的 RDP/SSH 連線，降低營運管理成本，達到最佳黃金資安策略！
    template(#session-4)
      h3 2021 雲端資安新運動，台灣產業接招囉！
      p 進入新一代雲端世紀，企業現有的資安管理將遇到哪些雲端新挑戰？企業不分規模，都必須思考效益最佳化的資安升級策略。本集節目邀請經驗豐富的 SI 專家以台灣在地案例，分享一套從 CEO 到 IT 人員必知的黃金資安守則。
  v-divider
  #form: v-form
  v-divider
  v-license
  v-divider
  v-footer
</template>

<script>
import VMain from '@/components/TrackMain.vue'
import VAgenda from '@/components/TrackAgenda.vue'
import VForm from '@/components/Form.vue'
import VLicense from '@/components/License.vue'
import VDivider from '@/components/Divider.vue'
import VFooter from '@/components/Footer.vue'

export default {
  name: 'Cloudsecurity',
  components: {
    VMain,
    VAgenda,
    VForm,
    VLicense,
    VDivider,
    VFooter,
  },
  data() {
    return {
      speakerIds: {
        session1: [19],
        session2: [20],
        session3: [21],
        session4: [35, 22, 23, 24],
      },
    }
  },
  created() {
    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

</style>

<template lang="pug">
#v-opensource
  v-main.opensource
    template(#title): h2 微軟開源新佈局，創新連擊全面加速
    template(#datetime): time 2021/3/31 12:30PM
    template(#description): p 企業如何掌握開源技術結合雲端帶來的創新優勢？本主題精選微軟全球Open Azure Day系列活動熱門議程，完整中文化影片讓台灣技術好手同步全球，掌握最新開源生態圈發展。讓全球技術大神帶領你快速瞭解開源基礎架構、開發、資料庫解決方案如何加速數位轉型。
    template(#rewards)
      figure: img(src='@/assets/track-1-whitepaper-1.jpg', alt='信賴 Red Hat OpenShift 及 Microsoft Azure 服務的九大理由', :width='$store.state.mobile ? 120 : 210')
  v-divider
  v-agenda.opensource(:speaker-ids='speakerIds')
    template(#session-1)
      h3 掌握開源趨勢，賦能企業未來
      p 企業布局數位轉型，從開放混合雲的基礎架構到產品生命週期的管理，可能面臨諸多關鍵業務應用程式上雲的新挑戰。K8s 聯合創辦人 Brendan Burns 和 Scott Guthrie 聯手揭示微軟如何攜手 Linux 社群與開源領域領導廠商，從上游優化開發者體驗，幫助企業直球對決轉型難題。
    template(#session-2)
      h3 2021 微軟開源新佈局，雲端生態系再進化
      p K8s、SONiC、Linux、 .NET Core，以及GitHub，開發者還有哪些最先進的開源技術？微軟近年為何在開源生態圈中投入「上游」？邀請專家分享微軟如何攜手開源合作夥伴，廣泛採用 Linux 和開源技術，打造全新雲端生態系，協助企業推動內部 IT 環境和服務轉型。
    template(#session-3)
      h3 運用 Azure Red Hat OpenShift，快速解鎖「容器管理」新挑戰
      p Kubernetes 成為近年開發者高度關注的技術趨勢之一，然而面對容器管理需求增加，如何讓 K8s 發揮部署最大效能，幫助 DevOps 團隊保持高度生產力。Azure Red Hat OpenShift 是在 K8s 之上建構的完全託管服務，讓開發人員更容易管理和部署容器平台。此次將為您展示Azure Red Hat OpenShift 實作，包括創建項目，使用源代碼建構容器，達成多環境支援、版本管理、自動維護和升級。
    template(#session-4)
      h3 數位轉型下一步致勝關鍵：開源趨勢解讀
      p 邀請紅帽、Adobe、SUSE、Elastic、HashiCorp 和 Cloudera 等技術專家，共同討論如何運用最新開發技術，在混合部署模型中，完成關鍵應用程式的轉移，以及分享產業共同開發和協作的好處。
  v-divider
  #form: v-form
  v-divider
  v-license
  v-divider
  v-footer
</template>

<script>
import VMain from '@/components/TrackMain.vue'
import VAgenda from '@/components/TrackAgenda.vue'
import VForm from '@/components/Form.vue'
import VLicense from '@/components/License.vue'
import VDivider from '@/components/Divider.vue'
import VFooter from '@/components/Footer.vue'

export default {
  name: 'Opensource',
  components: {
    VMain,
    VAgenda,
    VForm,
    VLicense,
    VDivider,
    VFooter,
  },
  data() {
    return {
      speakerIds: {
        session1: [1, 2, 3],
        session2: [5, 4],
        session3: [7, 6],
        session4: [1, 2, 8, 3, 9, 10, 11, 12, 13],
      },
    }
  },
  created() {
    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

</style>

<template lang="pug">
hr.v-divider
</template>

<script>
export default {
  name: 'Divider',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

.v-divider
  background-color: white
  height: 1px
</style>

<template lang="pug">
form#v-form(@submit.prevent='submit'): .container
  p
    | 填寫資料，
    br(v-if='$store.state.mobile')
    | 即刻迎接 2021 開發新挑戰
  .fieldset
    .field
      label 姓名*
      input(v-model='fields.name', :disabled='status !== "idle"', name='name', type='text', required)
    .field
      label 公司電子郵件*
      input(v-model='fields.officeEmail', :disabled='status !== "idle"', name='office-email', type='email', required)
    .field
      label 手機*
      input(v-model='fields.phoneNumber', :disabled='status !== "idle"', name='phone-number', type='tel', placeholder='0912345678', title='僅可輸入數字', pattern='[0-9]+', required)
    .field
      label 公司電話
      input(v-model='fields.officePhoneNumber', :disabled='status !== "idle"', name='office-phone-number', type='tel', placeholder='02-2222-3333#123', title='僅可輸入數字, #, -', pattern='[0-9#-]+')
    .field
      label 公司名稱*
      input(v-model='fields.companyName', :disabled='status !== "idle"', name='company-name', type='text', required)
    .field
      label 職稱*
      input(v-model='fields.jobTitle', :disabled='status !== "idle"', name='job-title', type='text', required)
  .fieldset
    select(v-model='fields.industry', :disabled='status !== "idle"', name='industry', required)
      option(value='', selected, disabled) 產業*
      option(value='高科技製造') 高科技製造
      option(value='資訊軟體服務') 資訊軟體服務
      option(value='資訊硬體製造') 資訊硬體製造
      option(value='資訊安全') 資訊安全
      option(value='資訊系統整合') 資訊系統整合
      option(value='網路通訊') 網路通訊
      option(value='電信服務') 電信服務
      option(value='金融保險') 金融保險
      option(value='醫療生技') 醫療生技
      option(value='能源產業') 能源產業
      option(value='零售電商') 零售電商
      option(value='傳產製造') 傳產製造
      option(value='政府機關') 政府機關
      option(value='媒體行銷') 媒體行銷
      option(value='公協會/財團法人') 公協會/財團法人
      option(value='教育機構') 教育機構
      option(value='服務業') 服務業
      option(value='學生') 學生
      option(value='其他') 其他
    select(v-model='fields.companySize', :disabled='status !== "idle"', name='company-size', required)
      option(value='', disabled, selected) 公司規模*
      option(value='500人以下') 500人以下
      option(value='501-1000人') 501-1000人
      option(value='1001人以上') 1001人以上
  .privacy: label
    input(v-model='fields.privacy', :disabled='status !== "idle"', name='privacy', type='checkbox', required)
    | 我已詳閱並同意
    a(href='/主辦單位蒐集個資聲明及抽獎注意事項.pdf', target='_blank') 「主辦單位蒐集個資聲明」
  .submit: button(type='submit', :disabled='status !== "idle"') {{ buttonWording }}
</template>

<script>
import { __, reject, isEmpty, mergeRight, pipe } from 'ramda'
export default {
  name: 'Form',
  data() {
    return {
      status: 'finished', // idle, submitting, submitted, finished
      fields: {
        name: '',
        officeEmail: '',
        phoneNumber: '',
        officePhoneNumber: '',
        companyName: '',
        jobTitle: '',
        industry: '',
        companySize: '',
        privacy: false,
      },
    }
  },
  computed: {
    buttonWording() {
      if(this.status === 'submitting') {
        return '請稍候...'
      } else if(this.status === 'submitted') {
        return '已報名'
      } else if(this.status === 'finished') {
        return '活動已結束'
      } else {
        return '立即觀看'
      }
    },
  },
  methods: {
    submit() {
      this.status = 'submitting'
      const transform = pipe(mergeRight(__, this.$store.state.utm), reject(isEmpty))
      this.$store.dispatch('submit', transform(this.fields)).then(() => {
        this.status = 'submitted'
        this.$store.commit('setPopup', true)
      }).catch(err => {
        if(err.response.status === 409) {
          this.status = 'submitted'
          this.$store.commit('setPopup', true)
        }
        else if(err.response.data.detail) {
          alert(`您的資料提交失敗\n${err.response.data.detail}`)
          this.status = 'idle'
        } else {
          alert('系統忙碌中，請稍後再試')
          this.status = 'idle'
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-form
  +padding-y(28px, 40px)
p
  +font(20px, white, 1.5, .05em, null, center)
  font-family: 'DotGothic16'
  margin-bottom: 16px
.fieldset
  display: grid
  &:nth-of-type(1)
    grid-gap: 12px
    margin-bottom: 16px
    .field
      position: relative
      input[type='email'],
      input[type='tel'],
      input[type='text']
        +size(100%, 36px)
        background-color: transparent
        border-bottom: 1px solid #B4B4B4
        color: white
        padding-left: 8em
        transition: border-color .2s, opacity .2s
        &::placeholder
          +font(14px, lightgray, null, null, 300)
        &:-webkit-autofill
          transition: background-color 999999s 999999s, color 999999s 999999s
        &:focus
          border-color: white
        &:disabled
          opacity: .6
      label
        +font(14px, #99F4F8, null, .05em)
        +position(absolute, 50%, null, null, .5em)
        pointer-events: none
        transform: translateY(-50%)
  &:nth-of-type(2)
    grid-gap: 16px
    grid-template-columns: repeat(2, 1fr)
    margin-bottom: 24px
    select
      +background(url(~@/assets/dropdown-icon.png), 10px, no-repeat, right .5em center)
      +border(1px, #D6D6D6, 3px)
      +font(14px, black, null, .05em)
      +size(100%, 36px)
      transition: opacity .2s
      &:invalid
        color: #727272
      &:disabled
        opacity: .6
.privacy
  text-align: center
  margin-bottom: 28px
  label
    +font(12px, white, null, .05em)
    +iflex(null, center)
    a
      color: #99F4F8
.submit
  text-align: center
  button
    +background(url(~@/assets/primary-button-background.png), 100%, no-repeat, center center)
    +size(150px, 48px)
    +ifont(16px, white, null, .05em)
    &:disabled
      opacity: .6

@media (min-width: 1000px)
  #v-form
    +padding-y(120px, 100px)
  p
    font-size: 30px
    margin-bottom: 28px
  .fieldset
    +margin-x(auto)
    &:nth-of-type(1)
      grid-gap: 20px 80px
      grid-template-columns: repeat(2, 1fr)
      margin-bottom: 32px
    &:nth-of-type(2)
      grid-gap: 80px
      margin-bottom: 40px
  .privacy
    margin-bottom: 60px
    label
      font-size: 14px
  .submit
    button
      +size(200px, 64px)
      font-size: 24px
</style>

<template lang="pug">
footer#v-footer: .container
  ul.partners
    li
      h3 主辦單位
      img(src='@/assets/techorange-logo.png', alt='TechOrange 科技報橘', :height='$store.state.mobile ? 24 : 40')
    li
      h3 贊助單位
      img(src='@/assets/microsoft-logo.png', alt='Microsoft', :height='$store.state.mobile ? 24 : 40')
  small
    | Copyright © {{ year }} 流線傳媒有限公司 All rights reserved.
    |
    ul
      li: a(href='https://buzzorange.com/techorange/', target='_blank') TechOrange科技報橘
      li: a(href='https://buzzorange.com/', target='_blank') BuzzOrange報橘
      li: a(href='https://buzzorange.com/vidaorange/', target='_blank') VidaOrange生活報橘
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-footer
  +padding-y(32px, 16px)
.partners
  list-style-type: none
  margin-bottom: 40px
  > li
    text-align: center
    &:not(:last-child)
      margin-bottom: 30px
    h3
      +font(20px, white, null, .05em, null, left)
      font-family: 'DotGothic16'
      margin-bottom: 30px
      align-self: flex-start
      &::before
        content: '# '
small
  +font(12px, white, 1.5, null, 300, center)
  +margin-x(auto)
  display: block
  transform-origin: 0 50%
  transform: scale(.83)
  width: 120%
  ul
    list-style-type: none
    display: inline-flex
    li
      &:not(:last-child)::after
        content: '|'
        margin-left: .5em
        margin-right: .5em

@media (min-width: 1000px)
  #v-footer
    +padding-y(120px, 36px)
  .partners
    display: grid
    grid-template-columns: repeat(2, 1fr)
    justify-items: center
    margin-bottom: 112px
    > li
      &:not(:last-child)
        margin-bottom: 0
      h3
        font-size: 30px
        margin-bottom: 60px
  small
    font-size: 14px
    transform: none
    width: 100%
</style>

<template lang="pug">
a#v-shortcut(v-scroll-to='"#form"', href='#form') 立即觀看
</template>

<script>
export default {
  name: 'Shortcut',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-shortcut
  +flex(center, center)
  +ifont(20px, white, null, .2em, 500)
  +position(fixed, null, 0, 0, 0)
  background-color: #3513DC
  height: 52px
  &:hover
    text-decoration: none

@media (min-width: 1000px)
  #v-shortcut
    +background(url(~@/assets/shortcut-background.png))
    +font(18px, #EA335E, null, .1em)
    +position(null, null, 40px, 40px, unset)
    +size(148px, 100px)
    filter: drop-shadow(0 0 4px rgba(#EA335E, .6))
    align-items: flex-end
    background-color: transparent
    padding-bottom: 32px
</style>

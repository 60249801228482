<template lang="pug">
#v-popup
  .dimmer(@click='$store.commit("setPopup", false)')
  transition(name='popup', appear): .popup
    button.close(@click='$store.commit("setPopup", false)', type='butotn'): img(src='@/assets/black-close-icon.png', alt='關閉', :width='$store.state.mobile ? 28 : 36')
    h3 您已成功報名！
    .videos
      a(href='https://youtube.com/playlist?list=PLXub2Md7eWrTO79ntXm0kLxQK373dzKbt', target='_blank') 點我立即觀看
    // p
    //   | 將下列活動加入行事曆，
    //   br(v-if='$store.state.mobile')
    //   | 4 場精彩論壇資訊不漏接
    // ul
      li
        time
          .date 3/31(三)
          .time 12:30pm
        a.disabled(href='https://calendar.google.com/calendar/u/0/r/eventedit?text=Cloud+Innovation+Summit:+Open+Source&dates=20210331T043000Z/20210331T100000Z&details=%E5%BE%AE%E8%BB%9F+ALL+in+%E9%96%8B%E6%BA%90%EF%BC%8C%E9%96%8B%E7%99%BC%E5%89%B5%E6%96%B0%E9%80%A3%E6%93%8A%E3%80%82%E5%BE%AE%E8%BB%9F+%E2%9D%A4%EF%B8%8F+Open+Source%EF%BC%81Kubernetes+%E8%81%AF%E5%90%88%E5%89%B5%E8%BE%A6%E4%BA%BA%E8%A6%AA%E8%87%AA%E8%A7%A3%E5%AF%86%EF%BC%8C%E5%A6%82%E4%BD%95%E9%9D%A2%E5%B0%8D%E6%B7%B7%E5%90%88%E9%9B%B2%E3%80%81%E5%AE%B9%E5%99%A8%E5%8C%96%E8%B6%A8%E5%8B%A2%EF%BC%9F+%E9%A6%96%E6%92%AD%E6%97%A5%E6%9C%9F%EF%BC%9A3/31&location&pli=1', target='_blank') +
      li
        time
          .date 4/7(三)
          .time 12:30pm
        a.disabled(href='https://www.google.com/calendar/render?action=TEMPLATE&text=Cloud%20Innovation%20Summit%3A%20Data%20%26%20AI&dates=20210407T043000Z%2F20210407T100000Z&details=%E6%96%B0%E4%B8%80%E6%B3%A2%20AI%20%E7%AB%B6%E8%B3%BD%E8%B5%B7%E8%B7%91%EF%BC%8C%E6%95%B8%E6%93%9A%E6%88%B0%E7%94%9F%E6%AD%BB%E3%80%82%E9%96%8B%E7%99%BC%E6%95%8F%E6%8D%B7%E5%BA%A6%E5%A6%82%E4%BD%95%E4%B8%8D%E8%A2%AB%E3%80%8C%E9%9B%B2%E6%B5%B7%E6%95%B8%E6%93%9A%E3%80%8D%E6%8B%96%E5%9E%AE%EF%BC%9F%E8%A6%96%E8%A6%BA%E8%BE%A8%E8%AD%98%E3%80%81%E6%A9%9F%E5%99%A8%E5%AD%B8%E7%BF%92%E9%80%B2%E9%9A%8E%E7%89%88%EF%BC%8C%E6%BB%BF%E6%BB%BF%E6%8A%80%E8%A1%93%E5%AF%A6%E6%88%B0%E7%B2%BE%E8%8F%AF%E4%B8%8D%E8%97%8F%E7%A7%81%EF%BC%81%20%E9%A6%96%E6%92%AD%E6%97%A5%E6%9C%9F%EF%BC%9A4%2F7&location=', target='_blank') +
      li
        time
          .date 4/14(三)
          .time 12:30pm
        a.disabled(href='https://calendar.google.com/calendar/u/0/r/eventedit?text=Cloud+Innovation+Summit:+Cloud+Security&dates=20210414T043000Z/20210414T100000Z&details=%E5%BE%9E+Day1+%E5%B0%B1%E8%AE%93%E7%8F%8D%E8%B2%B4+Data+%E5%AE%89%E5%85%A8%E7%84%A1%E8%99%9E%E3%80%82%E9%9B%B2%E7%AB%AF%E8%B3%87%E5%AE%89%E6%94%BB%E9%98%B2%E6%88%B0%EF%BC%81%E7%B8%BD%E7%B5%90%E5%B9%B4%E5%BA%A6%E8%B3%87%E5%AE%89%E6%96%B0%E6%A6%82%E5%BF%B5%EF%BC%9A%E7%94%A8%E6%A9%9F%E5%99%A8%E5%AD%B8%E7%BF%92%E6%8E%8C%E6%8F%A1%E9%A7%AD%E5%AE%A2%E6%94%BB%E6%93%8A%E9%80%94%E5%BE%91%EF%BC%8C%E6%89%93%E9%80%A0%E9%98%B2%E5%A0%B5%E9%A7%AD%E5%AE%A2%E9%98%B2%E8%AD%B7%E7%B6%B2%E3%80%82+%E9%A6%96%E6%92%AD%E6%97%A5%E6%9C%9F%EF%BC%9A4/14&location&pli=1', target='_blank') +
      li
        time
          .date 4/21(三)
          .time 12:30pm
        a.disabled(href='https://calendar.google.com/calendar/u/0/r/eventedit?text=Cloud+Innovation+Summit:+Cloud+Native+Application&dates=20210421T043000Z/20210421T100000Z&details=App+%E5%B7%B2%E6%AD%BB%EF%BC%9F%E9%9B%B2%E7%AB%AF%E9%96%8B%E7%99%BC%E7%94%9F%E6%85%8B%E5%A4%A7%E7%88%86%E7%99%BC%E3%80%82%E9%9B%B2%E5%8E%9F%E7%94%9F%E3%80%81K8s+%E9%96%8B%E7%99%BC%E8%AE%8A%E6%88%90+IT+%E6%96%B0%E5%B8%B8%E6%85%8B%EF%BC%8C%E5%A6%82%E4%BD%95%E6%8A%8A%E5%BE%AE%E6%9C%8D%E5%8B%99%E6%9E%B6%E6%A7%8B%E7%99%BC%E6%8F%AE%E5%BE%97%E6%B7%8B%E6%BC%93%E7%9B%A1%E8%87%B4%EF%BC%9F5G+%E9%AB%98%E9%80%9F%E6%95%B8%E6%93%9A%E5%82%B3%E8%BC%B8%EF%BC%8C%E4%BC%81%E6%A5%AD%E5%82%99%E6%88%B0%E9%A0%90%E5%82%99%EF%BC%81+%E9%A6%96%E6%92%AD%E6%97%A5%E6%9C%9F%EF%BC%9A4/21&location&pli=1', target='_blank') +
</template>

<script>
export default {
  name: 'Popup',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

.popup-enter-active, .popup-leave-active
  transition: opacity .2s .2s, transform .2s .2s
.popup-enter, .popup-leave-to
  opacity: 0
  transform: translateY(-20px)

#v-popup
  +flex(center, center)
  +position(fixed, 0, 0, 0, 0)
  z-index: 99
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .8)
.popup
  background-color: white
  border-radius: 20px
  padding: 36px 40px 60px
  position: relative
.close
  +position(absolute, 8px, 8px)
  padding: 5px
h3
  +ifont(24px, black, 1.5, .05em, null, center)
  font-family: 'DotGothic16'
  margin-bottom: 8px
p
  +font(16px, black, 1.5, .05em, 500, center)
  margin-bottom: 32px
ul
  display: grid
  grid-gap: 12px
  grid-template-columns: repeat(2, 120px)
  list-style-type: none
  > li
    &:nth-child(1)
      +border(2px, #99F4F8)
      time
        background-color: #99F4F8
    &:nth-child(2)
      +border(2px, #BF7DF5)
      time
        background-color: #BF7DF5
    &:nth-child(3)
      +border(2px, #FCF1E6)
      time
        background-color: #FCF1E6
    &:nth-child(4)
      +border(2px, #EA335E)
      time
        background-color: #EA335E
    time
      +padding-y(8px)
      display: block
      .date
        +font(20px , black, 1.5, .05em, null, center)
        font-family: 'DotGothic16'
      .time
        +font(16px, black, null, .05em, null, center)
        font-family: 'DotGothic16'
    a
      +flex(center, center)
      +font(48px, black, 72px)
      &:hover
        text-decoration: none
.videos
  margin-top: 20px
  text-align: center
  a
    +font(20px, CornflowerBlue, null, null, center)
    text-decoration: underline

@media (min-width: 1000px)
  .popup
    padding: 60px 80px
  .close
    +position(null, 16px, 16px)
  h3
    font-size: 24px
  p
    font-size: 20px
  ul
    grid-gap: 20px
    grid-template-columns: repeat(4, 142px)
    > li
      time
        .date
          font-size: 24px
        .time
          font-size: 20px
</style>
